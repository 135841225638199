:root {
  --bg-color: #1e1e1e;
  --fg-color: white;
  --accent: #31c4d8;
  --primary: #245056;
  --secondary: #343434;
  --secondary-transparent: rgba(255, 255, 255, 0.05);
  --black: black;
  --margin-vert: 20px;
  --margin-side: 32px;
}

@font-face {
  font-family: "Franklin ITC";
  src: url("./fonts/Franklin_ITC-Light.woff2") format("woff2"),
    url("./fonts/Franklin_ITC-Light.woff") format("woff");
}

body {
  font-size: 20px;
  font-family: "Franklin ITC", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--bg-color);
  color: var(--fg-color);
  line-height: 1.3;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
