.App {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.App-header {
  position: sticky;
  top: 0;
  background: var(--bg-color);
  margin: 0 var(--margin-side);
  padding-top: var(--margin-vert);
}

.App-header.flex h1 {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.header-buttons {
  display: inline-flex;
  flex-wrap: wrap;
  align-self: center;
  margin-bottom: -8px;
}

.header-buttons > * {
  margin-bottom: 8px;
}

.extra-right {
  margin-right: 45px;
}

.data-editor {
  flex: 1 0 auto;
  margin: 0 var(--margin-side);
}

div.matching-footer-buttons,
div.matching-footer-buttons-mobile {
  flex-shrink: 0;
  margin: 10px var(--margin-side);
  margin-bottom: calc(var(--margin-vert) * -0.5 - 6px);
}

.matching-footer-buttons select {
  margin-bottom: 6px;
}

.main {
  flex: 1 0 auto;
  margin: 0 var(--margin-side);
}

.App-logo {
  height: 46px;
  pointer-events: none;
}

h1 {
  font-size: 50px;
  margin: 0;
}

h1 *,
button * {
  display: inline-block;
  vertical-align: middle;
}

h1 img {
  margin-right: 15px;
}

button {
  padding: 6px 8px;
  border-radius: 2px;
  color: white;
  border: none;
  font-size: 20px;
  margin-right: 8px;
}

button.slim {
  padding: 2px 8px;
}

button img {
  margin: 0 5px 0 2px;
}

button.primary-button {
  background: var(--primary);
}

button.secondary-button {
  background: var(--secondary);
}

button.inverted-button {
  background: var(--black);
  color: var(--accent);
}

button.danger-button {
  background: crimson;
}

label {
  display: inline-flex;
}

label * {
  pointer-events: none;
}

.hidden {
  display: none;
}

table {
  font-family: monospace;
  font-size: 15px;
  border-collapse: collapse;
  margin: 20px 0;
}

th,
td {
  text-align: left;
  padding: 3px 12px;
}

.hoverable td,
th {
  cursor: pointer;
}

th {
  border-bottom: solid 1.5px white;
  padding-bottom: 4px;
}

tr:first-child td {
  padding-top: 4px;
}

td.hover,
th.hover {
  background: var(--black);
  color: var(--accent);
}

.button-section {
  margin: 10px 0;
}

.column-selection {
  font-size: 14px;
  background: var(--primary);
  padding: 2px 4px;
  border-radius: 3px;
  margin: 3px;
}

.remove-action {
  display: inline-block;
  margin-right: 5px;
}

.warn {
  background: var(--secondary);
  color: var(--accent);
  font-size: 15px;
  display: table;
  padding: 12px 17px;
  border-radius: 3px;
}

.extra-top {
  margin-top: 30px;
}

.extra-bottom {
  margin-bottom: 8px;
}

.tick {
  background: var(--primary);
  border-radius: 2px;
  height: 28px;
  width: 6px;
  margin-right: 3px;
  display: inline-block;
}

.tick.lit {
  background: var(--accent);
}

button.disabled {
  opacity: 0.3;
}

.button-category-label {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 2px;
  margin-top: 20px;
  padding: 0;
}

select {
  margin: 0 5px;
  background: var(--black);
  color: var(--fgColor);
  font: inherit;
  border-radius: 2px;
  padding: 0 10px;
}

.filter {
  background: var(--black);
  color: var(--fgColor);
  font-family: inherit;
  font-size: 18px;
  border: none;
  border-radius: 2px;
  margin-right: 10px;
  padding: 2px 10px;
  border: solid 1px var(--primary);
}

.bar-container {
  top: 0;
  left: 0;
  right: 0;
  background: var(--black);
  height: 8px;
  position: fixed;
}

.bar {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background: var(--accent);
}

.low-bottom h1 {
  margin-bottom: 12px;
}

.footer {
  margin-top: var(--margin-vert);
  padding: 0 var(--margin-side);
  background: var(--black);
  padding: 7px 32px 10px 32px;
  border-top: solid 1px var(--secondary);
  flex-shrink: 0;
  position: sticky;
  bottom: 0;
}

.footer a {
  text-decoration: none;
}

nav * {
  display: inline-block;
  font-size: 16px;
  vertical-align: middle;
}

nav > * {
  margin-right: 10px;
}

a,
.inline-button {
  text-decoration: underline;
  color: var(--fg-color);
}

.inline-button,
button,
label {
  cursor: pointer;
}

.secondary {
  color: var(--secondary);
}

.footer img {
  height: 20px;
}

.no-select {
  user-select: none;
}

p {
  max-width: 60ch;
}

.advanced {
  background: var(--secondary-transparent);
  max-width: 50ch;
  border-radius: 4px;
  padding: 1px 16px 16px 16px;
  font-size: 16px;
  display: inline-flex;
  flex-direction: column;
}

.intro-container {
  display: flex;
  row-gap: 20px;
  flex-wrap: wrap;
  align-items: flex-start;
}

.hamburger,
.matching-footer-buttons-mobile {
  display: none;
}

.hamburger img {
  height: 20px;
}

.legend {
  margin: 2px var(--margin-side) -3px var(--margin-side);
}

.legend-name {
  color: var(--white);
  text-transform: uppercase;
  font-size: 12px !important;
  margin-right: 10px;
  display: inline-block;
  vertical-align: baseline;
}

.legend > *,
.legend-text {
  display: inline-block;
  vertical-align: middle;
  font-size: 16px;
}

.legend-group:not(:last-child) {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}

.square {
  display: inline-block;
  vertical-align: middle;
  width: 15px;
  height: 15px;
  box-sizing: border-box;
  margin-right: 5px;
}

.square.legend-matched {
  background: var(--accent);
  border: solid 1px var(--secondary);
}

.square.legend-unmatched {
  background: var(--secondary);
  border: solid 1px var(--secondary-transparent);
}

@media only screen and (max-width: 800px) {
  .header-buttons,
  .matching-footer-buttons {
    display: none !important;
  }

  .header-buttons.hamburger-open {
    display: inline-flex !important;
  }

  .matching-footer-buttons.hamburger-open {
    display: block !important;
  }

  .data-editor.hamburger-open,
  .legend.hamburger-open {
    pointer-events: none;
    opacity: 30%;
    filter: grayscale(1) blur(2px);
  }

  .hamburger,
  .matching-footer-buttons-mobile {
    display: block;
  }

  .matching-footer-buttons-mobile.hamburger-open {
    display: none;
  }

  .crosswalker-text {
    font-size: 30px;
  }
}
